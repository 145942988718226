import React from 'react'

import BannerSliderItem from './bannerslideritem';

import SwiperCore, {Pagination, Autoplay, A11y  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import './style.scss'

import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/navigation/navigation.scss'

SwiperCore.use([Pagination, Autoplay, A11y ]);

export default function BannerSlider({ banners }) {

  var swiperContent = 

    banners?.map((banner, i) => {
      return(
        <SwiperSlide key={i}> 
          <BannerSliderItem banner={banner} />
        </SwiperSlide>
      )
    })
  
  return (
    <>
      <section className="mediaSliderWrapper" style={{display: `${banners.length < 1 ? 'none' : 'block'}`}}>
        <Swiper
          autoplay
          pagination={{ clickable: true }}
          spaceBetween={50}
          slidesPerView={1}
          observer={true}
        >
         {swiperContent}
        </Swiper>
      </section>
    </>
  )
}

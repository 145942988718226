import React, { useState, useEffect } from "react";

import api, {apiMedias} from "../../service/api";
import FindMediaService from "../../service/findMedia";
import { useTranslation } from "react-i18next";
import MovieTabList from "../../components/movietablist";
import MovieGrid from "../../components/moviegrid";
import Loader from "../../components/loader";
import { useNoInitialEffect } from '../../hooks/useNoInitialEffect'
import { useParams } from 'react-router-dom'
import {groupTitles} from '../../config'

import "./style.scss";
import axios from "axios";
import FadeIn from "../../components/fadein";

export default function Movies() {
  const { t, i18n } = useTranslation();
  const [movies, setMovies] = useState([]);
  const [currentGenreId, setcurrentGenreId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [hasMoreContent, setHasmoreContent] = useState(true);
  const [sections, setSections] = useState([]);
  const [order, SetOrder] = useState({ by: 10, sort: "DESC" });
  const [currentLang, setCurrentLang] = useState(i18n.language);
  const [pageTitle, setPageTitle] = useState('');
  const { groupId } = useParams()

  i18n.on("languageChanged", function (lng) {
    setCurrentLang(lng);
  });

  useEffect(() => {
    getSections();
    getMedias()
  }, []);

  async function getMedias() {
    var groupIdParameter
    if(groupId !== undefined) {
      groupIdParameter = groupId
    } else {
      groupIdParameter = 1;
    }
    const mediaIds = await axios.get(`https://integration.ottvs.com.br/ServiceMarketingAssets/GroupView/GetGroupView?groupViewId=${groupIdParameter}`)
    var groupName = groupTitles.find(group => group.id === Number(groupIdParameter)).title
    setPageTitle(groupName !== undefined ? groupName : ' ')

    const { Url, Body } = FindMediaService(
      currentGenreId,
      currentPage,
      null,
      null,
      0,
      order.by,
      order.sort,
      mediaIds.data.map((movie) => { return movie.MediaId; }),
      currentLang
    );

     
    if (hasMoreContent) {
      setIsLoading(true);
      try {
        const result = await apiMedias.post(Url, Body);

        if (currentPage === 0) {
          setMovies(result.data.FindMediaResult.Movies);
        } else {
          setMovies((movies) => [
            ...movies,
            ...result.data.FindMediaResult.Movies,
          ]);
        }

        if (result.data.FindMediaResult.Movies.length < 1) {
          setHasmoreContent(false);
        }

        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        setRequestError(true);
      }
    } else {
      setIsLoading(false)
    }

  }

  function getGenre(id, i) {
    setcurrentGenreId(id);
    let updatedSections = sections;
    clearSectionActive(updatedSections);
    updatedSections[i]["active"] = true;
    setSections(updatedSections);
  }

  function clearSectionActive(sections) {
    sections.forEach((movie) => {
      if (movie.active) {
        movie.active = false;
      }
    });
  }

  async function getSections() {
    try {
      const result = await api.get(
        `https://ottvsimg.blob.core.windows.net/res/Elite/ListSectionl${currentLang}.json`
      );
      setSections(result.data.ListSection);

      // getMedias(result.data.ListSection[0].Id, currentPage)
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    function handleScroll() {
      const scrollHeight = document.documentElement.scrollHeight;
      var clientHeight = document.documentElement.clientHeight;
      var scrollTop = document.documentElement.scrollTop;

      if (scrollHeight - scrollTop === clientHeight) {
        setCurrentPage((_currentPage) => _currentPage + 1);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [currentPage]);

  useNoInitialEffect(() => {
    if (currentPage === 0) {
      getMedias()
      setIsLoading(true);
    }
    setCurrentPage(0);
    setHasmoreContent(true);
    console.log('mudou de genero')
  }, [currentGenreId]);

  useNoInitialEffect(() => {
    getMedias()
  }, [currentPage])

  useNoInitialEffect(() => {
    getMedias()
    setCurrentPage(0);
    setHasmoreContent(true);
  }, [order]);
  
  useNoInitialEffect(() => {
    // getSections();
    getMedias() 
    setCurrentPage(0);
    setHasmoreContent(true);
  }, [currentLang]);

  function handleSelectChange(e) {
    let OrderBy = Number(e.target.value)
    SetOrder({ by: OrderBy, sort: OrderBy === 10 ? "DESC" : "ASC" })
  }

  return (
    <FadeIn>
      <div className="container">       
      <div className="moviesContent">
        <>
          <h1 className="moviesTitle">{t(pageTitle)}</h1>

          <div className="selectOrderWrapper">
            <div className="selectOrder">
              <select name="slct" value={order.by} onChange={(e) => handleSelectChange(e)}>
                <option value="10">{t('Ano de Produção')}</option>
                <option value="1">A &rarr; Z</option>
              </select>
            </div>
          </div>

          <MovieTabList sections={sections} getGenre={getGenre} />
          <MovieGrid movies={movies} />
          {isLoading ? (
            <div className="centered">
              <Loader />
            </div>
          ) : (
            ""
          )}
          {requestError ? (
            <div className="centered">

              <h3>
                <b>Problem to load this content :(, try again.</b>
              </h3>
            </div>
          ) : (
            ""
          )}
        </>
      </div>
    </div>
    </FadeIn>
  );
}

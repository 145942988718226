import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'
import { useTranslation } from 'react-i18next';
import Cover from '../../cover'

export default function SliderItem(props) {
  const {Images, FullTitle, Metadata, Id} = props.media;
  const { t } = useTranslation();
  return (
    <div className="SliderItem">
      <div className="cover">
        <Link to={`/movies/${Id}`}>
          <Cover image={Images} title={FullTitle}/>
        </Link>
      </div>
      <div className="description">
        <h3>{ FullTitle }</h3>
        <p><b>{t('Título Original')}</b>: { Metadata.OriginalName }</p>
        <p><b>{t('País')}</b>: { Metadata.Country }</p>
        <p><b>{t('Direção')}</b>:&nbsp;
        { 
          Metadata.Directors.map((director, i) => {
            return (i + 1) ===  Metadata.Directors.length ? (`${director.Name} `) : (`${director.Name}, `)
          })
        }
        </p>
        <p><b>{t('Estrelando')}</b>:&nbsp;
        { 
          Metadata.Actors.map((actor, i) => {
            return (i + 1) ===  Metadata.Actors.length ? (`${actor.Name} `) : (`${actor.Name}, `)
          })
        }
        </p>
     
      </div>
    </div>
  )
}

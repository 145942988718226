export default function FindMediaService(
  genre = null,
  pageNumber = 0,
  mediaId = null,
  name = null,
  sectionId = 0,
  SortCriteria = 10,
  SortOrder = "DESC",
  mediasId = null,
  language = 'pt',
  recordsPerPage = 15
  
) {

  return {
    "Url": "/findmedia",
    "Body": {
      "Criteria": {
        "MediaType": 31,
        "DistributorId": null,
        "MediasId": mediasId,
        "Genre": genre,
        "UseOrInCategories": true,
        "SectionId": sectionId,
        "MediaId": mediaId,
        "Name": name,
        "Lang": language,
        "AgeRatingId": 0
      },
      "Entities": [
        {
          "EntityName": "Genres",
          "EntityProperties": "Id|Name|IsMain"
        }
      ],
      "Groups": [
        {
          "GroupName": "Metadata",
          "GroupProperties": "Description|Genres|Distributor|UniqueUrl|Actors|Directors|OriginalName|Country|Year|Censure|TrailerUrl"
        },
        {
          "GroupName": "Images",
          "GroupProperties": "TypeId|Url"
        }, {
          "GroupName":"FileInfo",
          "GroupProperties":"Duration|Subtitles|Audios|Resolution"
        }
      ],
      "Options": {
  
        "BoxBehavior": 1,
        "FillParent": false,
        "FillSiblings": false,
        "FillSiblingsChilds": false,
        "IncludeNullPriceItens":true,
        "IncludePreOrderItens" :true,
        "IncludeCinemaItens": true,
        "language": language,
        "ImageTypeIds": [
          -2
        ],
        "PageNumber": pageNumber,
        "RecordsPerPage": recordsPerPage,
        "SortCriteria": SortCriteria,
        "SortOrder": SortOrder
      },
      "AuthenticationTicket": "looke@looke:v7c8ad@#$"
    }

  }
}
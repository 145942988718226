import React, { useContext } from 'react'
import { useHistory } from "react-router-dom";
import './style.scss'

import { AiOutlineSearch } from "react-icons/ai";
import { searchContext } from '../../../../context/searchcontext'

export default function Search() {
	const history = useHistory();
	const {search, setSearch} = useContext(searchContext)

	const handleTypeSearch = (value) => {
		history.push("/search");
		setSearch(value)
	}

	return (
		<div className="search">
		   <input type="text" onChange={(e) => handleTypeSearch(e.target.value)}/>
		   <AiOutlineSearch className="searchIcon" color={"#000000"} size={'25px'}/>
		</div>
	)
}

import React from 'react';

import MovieGridItem from './moviegridItem'

import './style.scss'

export default function MovieGrid({ movies }) {

  return (
    <>
      <div className="movieGrid">
        {
          movies?.map((movie, i) => {
            return (
              <MovieGridItem movie={movie} key={i}/>
            )
          })
        }

      </div>
    </>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

import { useTranslation } from 'react-i18next';

export default function NavList() {
	const { t } = useTranslation();
	return (
		<div className="navlist">
			<ul>
				<li><Link to="/movies">{t('Nossos Filmes')}</Link></li>
				<li><Link to="/news">{t('Imprensa')}</Link></li>
				<li><Link to="/contact">{t('Contatos')}</Link></li>
				<li><Link to="/about">{t('Quem Somos')}</Link></li>
			</ul>
		</div>
	)
}


import React from 'react'
import { useHistory } from 'react-router-dom'

import './style.scss'

export default function MovieNotFound() {

  const history = useHistory()

  return (
    <div className="notFoundWrapper" >
      <h1>Movie not found :(</h1>
      <p onClick={() => history.goBack()}>  &#8592; Back</p>
    </div>
  )
}

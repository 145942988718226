import React, { useEffect, useState } from 'react'
import './style.scss'
import FalbackCover from './fallbackcover'
import FadeIn from '../fadein';

export default function Cover({ title, image }) {
  const [coverError, setCoverError] = useState(false);

  const handleError = () => {
    setCoverError(true)
  }

  useEffect(() => {
    setCoverError(false)
  }, [image])

  return (
    <FadeIn>
      {
        coverError ?
          <FalbackCover title={title} />
          :
          <img
            src={image[0]?.Url}
            alt="cover movie"
            className="cover"
            onError={() => handleError()}
          />
      }
    </FadeIn>
  )
}
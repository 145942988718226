export function ListBannerService( bannerId ) {
  const sitetypeEnum = {
    'pt': 991,
    'en': 992,
    'es': 993
  }

  return   {
    "Url": "/ListBanner",

    "Body":
    {
      "BannerLocationId": sitetypeEnum[bannerId] || 991,
      "AgeRatingId": 8,
      // "BannerLocationId": 100,
      "AuthenticationTicket": "looke@looke:v7c8ad@#$"
    }
  }

} 
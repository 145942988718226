import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'
import Cover from '../../cover'

export default function SliderItem({ media, isLoading }) {
  const { Images, Id, FullTitle } = media;

  return (
    <div className="SectionSliderItem">

      <div className="cover">
        <Link to={`/movies/${Id}`}>
          <Cover title={FullTitle} image={Images} />
        </Link>
      </div>
    </div>
  )

}

import React, { useCallback, useEffect, useState } from 'react'


import SliderItem from './slideritem';

import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import './style.scss'

import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/navigation/navigation.scss'
import axios from 'axios';
import FindMediaService from '../../service/findMedia';
import{apiMedias} from '../../service/api';
import { useTranslation } from 'react-i18next';
import Loader from '../loader'
import { Link } from 'react-router-dom';
import { useNoInitialEffect } from '../../hooks/useNoInitialEffect';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function SectionSlider({ title, groupViewId, sortCriteria, sortOrder }) {
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language)
  const [currentPage, setcurrentPage] = useState(0);
  const [mediaListData, setMediaListData] = useState([]);
  const [HasMoreContent, setHasMoreContent] = useState(true)
  const [isListsLoading, setisListsLoading] = useState(true)
  const [listError, setListError] = useState(false)


  i18n.on('languageChanged', function (lng) {
    setCurrentLang(lng)
  })

  const listenPosition = (activeIndex, itemsLoaded) => {
    const intemsOnScreenAndExtra = 14;

    if (activeIndex + intemsOnScreenAndExtra >= itemsLoaded) {
      if (HasMoreContent === true) {
        NextPage()
      }
    }
  }

  const NextPage = () => {
    setcurrentPage((currentPage) => currentPage + 1)
  }

 const getMedias = useCallback((async () =>  {
  currentPage === 0 && setisListsLoading(true)
  try {
    const moviesIds = await axios.get(`https://integration.ottvs.com.br/ServiceMarketingAssets/GroupView/GetGroupView?groupViewId=${groupViewId}`);
    const { Url, Body: BodyCatalogo } = FindMediaService(
      null,
      currentPage,
      null,
      null,
      0,
      sortCriteria,
      sortOrder,
      moviesIds.data.map((movie) => movie.MediaId).reverse(),
      currentLang,
      30
    );


    if (HasMoreContent) {
      const moviesListData = await apiMedias.post(Url, BodyCatalogo)

      if (currentPage === 0) {
        setMediaListData(moviesListData.data.FindMediaResult.Movies)
      } else {
        setMediaListData((mediaListData) => [
          ...mediaListData,
          ...moviesListData.data.FindMediaResult.Movies
        ])
      }
      if (moviesListData.data.FindMediaResult.Movies.length < 1) {
        setHasMoreContent(false);
      }

    }
    setisListsLoading(false)
  } catch (error) {
    setListError(true)
    console.log(error)
    setisListsLoading(false)
  }

 })) 



  useEffect(() => {
    if (HasMoreContent === true) {
      getMedias()
    }
    //  console.log(currentPage)
  }, [currentPage]);

  useNoInitialEffect(() => {
    getMedias()
    setcurrentPage(0)
    setHasMoreContent(true)
  }, [currentLang])

  return (
    <>
      {
       !listError ? 
       isListsLoading ?
       <div className="centered"> <Loader  /></div>
       :
       <section className="sectionSliderWrapper">
         <p className="titleSection"><Link to={`/section/${groupViewId}`}>{title}</Link></p>
         <Swiper
           slidesPerGroup={4}
           slidesPerView={5}
           navigation
           spaceBetween={15}
           observer={true}
           onSlideChange={(swiperCore) => {
             const {
               activeIndex,
               slides
             } = swiperCore;
             listenPosition(activeIndex, slides.length)
           }}
           breakpoints={{
             768: {
               slidesPerView: 9,
             },
           }}

         >
           {
             mediaListData?.map((media, i) => {
               return (
                 <SwiperSlide key={i}>
                   <SliderItem isLoading={isListsLoading} media={media} />
                 </SwiperSlide>
               )
             })
           }
         </Swiper>
       </section>
       : 
       ''
      }


    </>
  )
}

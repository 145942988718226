import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss'

export default function MovieGridItemInfo({ info }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="triangleInfo">

      </div>
      <div className="MovieGridItemInfo">
        <div className="headerInfo">
          <h3><b className="titleUppercase">{info.FullTitle} - {`(${info.Metadata.Year})`}</b></h3>
        </div>

        <p><b className="titleUppercase">{t('Título Original')}: </b> {info.Metadata.OriginalName}</p>
        <p><b className="titleUppercase">{t('País')}: </b> {info.Metadata.Country}</p>
        <p> <b className="titleUppercase">{t('Sinopse')}: </b> {info.Metadata.Description}</p>
        <p> <b className="titleUppercase">{t('Direção')}: </b> 
          {
            info.Metadata.Directors.map((director, i) => {
              return (i + 1) === info.Metadata.Actors.length ? (`${director.Name} `) : (`${director.Name}, `)
            })
          }
        </p>
        <p><b className="titleUppercase">{t('Estrelando')}: </b>
        {
            info.Metadata.Actors.map((actor, i) => {
              return (i + 1) === info.Metadata.Actors.length ? (`${actor.Name} `) : (`${actor.Name}, `)
            })
          }
        </p>
      </div>
    </>
  )
}

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import MovieGridItemInfo from '../moviegriditeminfo'
import FadeIn from '../../fadein'
import Cover from '../../cover'
import './style.scss'

export default function MovieGridItem({ movie }) {
  const [showInfo, setShowInfo] = useState(false)

  function toggleInfo(state) {
    setShowInfo(state)
  }

  return (
    <FadeIn>
      <div className="movieGridItem"
        onMouseOver={() => toggleInfo(true)}
        onMouseLeave={() => toggleInfo(false)}
      >
        <Link to={`/movies/${movie.Id}`}>
          <Cover title={movie.FullTitle} image={movie.Images} />
        </Link>
        {showInfo ? <MovieGridItemInfo info={movie} /> : ''}
      </div>
    </FadeIn>
  )
}

import React, { useState } from 'react'
import './style.scss'
import Loader from '../../components/loader'

export default function ProfileCard({photo, name, position, email}) {

  const [isListsLoading, setisListsLoading] = useState(true)
  
  return (
    <article className="profileCard">
      { isListsLoading && <div className="centered" style={{margin: '0 100px'}}> <Loader/> </div>}
      <img className="profilePhoto" src={photo} alt="Elite Filmes Profile" onLoad={() => setisListsLoading(false)}/>
      <p className="profileName" >{name}</p>
      <p className="profilePosition" >{position}</p>
      <p className="profileEmail" >{email}</p>
    </article>
  )
}

import React from 'react'
import { Link } from 'react-router-dom';
import './style.scss'
import FadeIn from '../../fadein';

export default function BannerSliderItem(props) {
  const { ImageURL, Title, MediaId } = props.banner;

  return (
    <FadeIn>
      <div className="bannerSliderItem">
        <Link to={`/movies/${MediaId}`}>
          <img className="fluid-image" src={ImageURL} alt={Title || "Movie Cover"} />
        </Link>
      </div>
    </FadeIn>
  )
}

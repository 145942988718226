import React from 'react'
import './style.scss'
import Search from '../search'
import NavlistMobile from '../navlistmobile'
import { Link } from 'react-router-dom';
import Logo from '../../../../assets/logo.png'
import LanguageSelector from '../languageselector';

export default function NavigationMobile() {

	return (
		<nav className="navgationBarMobile">

			<div className="navWrapperLeft">
				<NavlistMobile />
				<Link to="/">
					<img className="logo" src={Logo} alt="Logo Elite Filmes" />
				</Link>
			</div>
			<div className="navWrapperLeft">
			<Search />
			<LanguageSelector/>
			</div >
		</nav>
	)
}

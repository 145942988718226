import React, { useEffect, useState } from 'react';
import './style.scss';

import Navigation from './navigation'
import NavigationMobile from './navigationmobile';

export default function Header() {
  const [showNavListMobile, setshowNavListMobile] = useState(false)

  useEffect(() => {
    function myFunction(x) {
      if (x.matches) { // If media query matches
        setshowNavListMobile(true)
      } else {
        setshowNavListMobile(false)
      }
    }

    var x = window.matchMedia("(max-width: 700px)")
    myFunction(x) // Call listener function at run time
    x.addListener(myFunction) // Attach listener function on state changes

  }, [])
  return (
    <header className="header ">
      {
        showNavListMobile ?
          <NavigationMobile />
          :
          <Navigation />
      }
    </header>
  )
}

import React from 'react'
import './style.scss'
import FalbackCover from '../../../assets/FallbackCover.png'
export default function FallbackCover({title}) {

  return (
    <article className="FallbackCover">
      <img class="cover" src={FalbackCover} alt="Cover Movie"/>
      <p>{title}</p>
    </article>
  )
}

import './theme/global.scss'
import './app.scss'
import { BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'
import { Suspense } from 'react'

import { useState } from 'react'
import Home from './pages/home'
import About from './pages/about'
import News from './pages/news'

import Header from './components/layout/header'
import Footer from  './components/layout/footer'
import Movies from './pages/movies'
import MovieDetail from './pages/moviedetail'
import Search from './pages/search'
import Contact from './pages/contact'


import { searchContext , DEFAULT_VALUE } from './context/searchcontext'

function App() {
  const [search, setSearch] = useState(DEFAULT_VALUE)
  return (
    <Suspense fallback={<p>Loading...</p>}>
    <>
      <div className="container-fluid">
      <BrowserRouter>
      <searchContext.Provider value={{ search , setSearch }}>
      <Header/>
        <Switch>
          <Redirect from='/mipcancun' to='/section/15' />
          <Route path={'/'} exact={true} component={Home}/>
          <Route path={'/about'} exact={true} component={About}/>
          <Route path={'/news'} exact={true} component={News}/>
          <Route path={'/movies'} exact={true} component={Movies} key='catalogo'/>
          <Route path={'/section/:groupId'} exact={true} component={Movies} key='section'/>
          <Route path={'/movies/:mediaId'} exact={true} component={MovieDetail}/>
          <Route path={'/search'} component={Search}/>
          <Route path={'/contact'} component={Contact}/>
        </Switch>
        <Footer/>
        </searchContext.Provider>
      </BrowserRouter>
      </div>
    </>
    </Suspense>
  );
}

export default App;

export const groupTitles = [
  {
    id:1,
    title: "Catalogo",
    sortCriteria: 10,
    sortOrder: "DESC"
  },
  {
    id: 2,
    title: "Novidades",
    sortCriteria: 2048,
    sortOrder: "ASC"
  },
  {
    id: 13,
    title: "Em Breve",
    sortCriteria: 2048,
    sortOrder: "ASC"
  },
   {
    id: 15,
    title: 'MARCHÉ DU FILM – CANNES',
    sortCriteria: 10,
    sortOrder: "DESC"
   }
   ,
   {
    id: 19,
    title: 'MIP Cancun',
    sortCriteria: 2048,
    sortOrder: "ASC"
   }
]
import './styles.scss'

const FadeIn = ({children}) => {
  return(
    <div className="fadeIn">
        {children}
    </div>
  )
}

export default FadeIn;
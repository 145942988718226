import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './style.scss'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useTranslation } from 'react-i18next';

export default function NavlistMobile() {

	const [showNavlistMobile, setshowNavlistMobile] = useState(false)

	function toggleMenu() {
		setshowNavlistMobile(!showNavlistMobile)
	}
	const { t } = useTranslation();
	return (
		<div>
		<span className="hamburgerWrapper" onClick={() => toggleMenu()}>
			<GiHamburgerMenu/>
		</span>

		<div className={showNavlistMobile ? "navlistMobile" : "navlistMobile hide"}>
			<div className="triangle"></div>
			<ul>
				<li onClick={() => toggleMenu()}><Link to="/movies">{t('Nossos Filmes')}</Link></li>
				<li onClick={() => toggleMenu()}><Link to="/news">{t('Imprensa')}</Link></li>
				<li onClick={() => toggleMenu()}><Link to="/contact">{t('Contatos')}</Link></li>
				<li onClick={() => toggleMenu()}><Link to="/about">{t('Quem Somos')}</Link></li>
			</ul>
		</div>
		</div>
	)
}

import React from 'react'
import './style.scss'
import Search from '../search'
import NavList from '../navlist'
import { Link } from 'react-router-dom';
import Logo from '../../../../assets/logo.png'
import LanguageSelector from '../languageselector';
import SocialIcons from '../../footer/socialicons'
export default function Navigation() {

	return (
		<nav className="navgationBar">
			<div className="navWrapperLeft">
				<Link to="/">
					<img className="logo" src={Logo} alt="Logo Elite Filmes" />
				</Link>

				<NavList />
				
			</div>

			<div className="navwrapperRight">
				<Search />
				<span className="hideOnMediumScreen">
					<SocialIcons/>
				</span>
				<LanguageSelector />
			</div>

		</nav>
	)
}

import React, { useEffect, useState } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import pt from '../../../../assets/icon-flag-1.jpg'
import en from '../../../../assets/icon-flag-2.jpg'
import es from '../../../../assets/icon-flag-3.jpg'
export default function LanguageSelector() {
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language)
  const [showMenuSelectLanguage, setshowMenuSelectLanguage] = useState(false)

  const flags = {
    pt,
    en,
    es
  };

  function toggleMenu() {
    setshowMenuSelectLanguage(!showMenuSelectLanguage)
  }

  const changeLanguage = (lng) => {
    localStorage.setItem("lng",lng)
    i18n.changeLanguage(lng);
    toggleMenu()
  };

  i18n.on('languageChanged', function (lng) {
    setCurrentLang(lng)
  })

  useEffect(() => {
    var lngLocal = localStorage.getItem("lng")
    if(lngLocal !== null) {
      setCurrentLang(lngLocal)
      i18n.changeLanguage(lngLocal)
    }
  },[])

  return (
    <div className="selectLangGroup">
      <div onClick={() => toggleMenu()} className="selectecFlag flagLang">
        <img src={flags[currentLang]} alt={flags[currentLang]} />
      </div>

      <div className={showMenuSelectLanguage ? "" : "hide"}>
        <div className="triangleLang"></div>
        <ul className="selectLangWrap">
          <li className="flagLang" onClick={() => changeLanguage('pt')}> <span><img src={pt} alt="Badeira do Brasil" /> pt</span></li>
          <li className="flagLang" onClick={() => changeLanguage('en')}> <span><img src={en} alt="Bandeira da Inglaterra" /> en</span></li>
          <li className="flagLang" onClick={() => changeLanguage('es')}> <span><img src={es} alt="Bandeira da Espanha" /> es</span></li>
        </ul>
      </div>

    </div>
  )
}

import React from 'react'

import SliderItem from './slideritem';

import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import './style.scss'

import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/navigation/navigation.scss'

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function MediaSlider(props) {

  return (
    <>
      <section className="sliderWrapper">
        <Swiper
          autoplay
          navigation
          pagination={{ clickable: true }}
          spaceBetween={50}
          slidesPerView={1}
          observer={true}
        >
          {
            props.medias?.map((media, i) => {
              return(
                <SwiperSlide  key={i}> 
                  <SliderItem media={media}/>
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      </section>
    </>
  )
}

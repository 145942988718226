import React, { useState, useEffect, } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import {apiMedias} from '../../service/api'
import FindMediaService from '../../service/findMedia';

import Loader from '../../components/loader'

import './style.scss'
import MovieNotFound from '../../components/movienotfound';
import Cover from '../../components/cover';
import FadeIn from '../../components/fadein';

export default function MovieDetail() {
  const { i18n, t } = useTranslation();

  let { mediaId } = useParams();
  const [currentLang, setCurrentLang] = useState(i18n.language)
  const [media, setMedia] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [movieNotFound, setMovieNotFound] = useState(false)

  
  i18n.on('languageChanged', function (lng) {
    setCurrentLang(lng)
  })

  async function getMedia() {

    const { Url, Body } = FindMediaService(null, 0, Number(mediaId), null, 16, 10, "DESC", null, currentLang);
    if(mediaId !== null && mediaId !== 'undefined') {
      try {
        const result = await apiMedias.post(Url, Body)
        const movies = result.data.FindMediaResult.Movies;
        setMedia(movies[0])
  
        if (movies.length < 1) {
          setMovieNotFound(true)
        }
        setIsLoading(false)
      } catch (e) {
        console.log(e)
      }
    } else {
      setMovieNotFound(true)
      setIsLoading(false)
    }
  }

useEffect(() => {
    getMedia() 
}, [currentLang])

  useEffect(() => {
    setIsLoading(true)
    getMedia()

  }, [])

  return (
    <FadeIn>
      <div className="container">
      <div className="MovieDetailContent">
        {isLoading ?
          <div className="centered"> <Loader /></div>
          :
          <>
            {
              movieNotFound ?
                <MovieNotFound />
                :
                <>
                  <div className="topDetail">
                    <div className="coverDetail">
                      <Cover image={ media?.Images} title={media.FullTitle}/>
                    </div>

                    <div className="descriptionDetail">
                      <h1 className="movieDetailTitle">{media?.FullTitle} - {`(${media?.Metadata.Year})`}</h1>

                      <p><b>{t('Título Original')}: </b> {media?.Metadata.OriginalName}</p>
                      <p><b>{t('País')}: </b> {media?.Metadata.Country}</p>
                      <p> <b>{t('Sinopse')}: </b> {media?.Metadata.Description}</p>
                      <p> <b> {t('Censura')}: </b> {media?.Metadata.Censure.Age === 0 ? "Livre" : media?.Metadata.Censure.Age}</p>
                      <p> <b>{t('Duração')}: </b> {`${media?.FileInfo.Duration} min`}</p>
                      <p> <b>{t('Direção')}: </b>
                        {
                          media?.Metadata?.Directors?.map((director, i) => {
                            return (i + 1) === media?.Metadata.Directors.length ? (`${director.Name} `) : (`${director.Name}, `)
                          })
                        }
                      </p>
                      <p><b>{t('Estrelando')}: </b>
                        {

                          media?.Metadata?.Actors?.map((actor, i) => {
                            return (i + 1) === media?.Metadata.Actors.length ? (`${actor.Name} `) : (`${actor.Name}, `)
                         
                          })
                        }
                      </p>
                    </div>
                  </div>

                  <div className="bottomDetail">
                    <div className="trailerWrapper">
                      <h3>Trailer</h3>
                      <video controls autoPlay muted src={media?.Metadata.TrailerUrl} />
                    </div>
                  </div>
                </>
            }
          </>
        }
      </div>
    </div>
    </FadeIn>
  )
}
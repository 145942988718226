import React from 'react'

import './style.scss';

export default function MovieTabItem({active, section, i, getGenre}) {
 
  return (
    <li 
      onClick={() => getGenre(section.Id, i)}
      className={active ? "movieTabItem listActive" : "movieTabItem"}
    >
      {section.Name}
    </li>
  )
}
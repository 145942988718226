import React, { useState, useEffect, useContext } from 'react'
import {apiMedias} from '../../service/api'
import FindMediaService from '../../service/findMedia'
import MovieGrid from '../../components/moviegrid'
import Loader from '../../components/loader'

import { searchContext } from '../../context/searchcontext'
import { useTranslation } from 'react-i18next';
import './style.scss'
import axios from 'axios'

export default function Movies() {
  const { t, i18n } = useTranslation();
  const { search, setSearch } = useContext(searchContext)
  const [movies, setMovies] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [hasMoreContent, setHasmoreContent] = useState(true);
  const [currentLang, setCurrentLang] = useState(i18n.language);

  i18n.on("languageChanged", function (lng) {
    setCurrentLang(lng);
  });

  async function getMedias() {    
    const mediaIdNovidades= await axios.get('https://integration.ottvs.com.br/ServiceMarketingAssets/GroupView/GetGroupView?groupViewId=2')
    const mediaIdCatalogo = await axios.get('https://integration.ottvs.com.br/ServiceMarketingAssets/GroupView/GetGroupView?groupViewId=1')
    const mediaIdEmbreve = await axios.get('https://integration.ottvs.com.br/ServiceMarketingAssets/GroupView/GetGroupView?groupViewId=13')

    const allMedias = [...mediaIdCatalogo.data, ...mediaIdEmbreve.data, ...mediaIdNovidades.data]

    if (hasMoreContent) {
      setIsLoading(true)

      const { Url, Body } = FindMediaService(
        null,
        currentPage,
        null,
        search, null,
        10,
        "DESC",
        allMedias.map((movie) => movie.MediaId),
        currentLang
      );

      try {

        if (search.length > 0) {
         var result = await apiMedias.post(Url, Body)
        } else {
          setMovies([])
          setIsLoading(false)
          return
        }

        if (currentPage === 0) {
          setMovies(result.data.FindMediaResult.Movies)
        } else {
          setMovies((movies) => [...movies, ...result.data.FindMediaResult.Movies]);
        }

        if (result.data.FindMediaResult.Movies.length < 1) {
          setHasmoreContent(false);
        }
        setIsLoading(false)
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    function handleScroll() {
      const scrollHeight = document.documentElement.scrollHeight
      var clientHeight = document.documentElement.clientHeight
      var scrollTop = document.documentElement.scrollTop

      if (scrollHeight - scrollTop === clientHeight) {
        setCurrentPage((_currentPage) => _currentPage + 1)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener("scroll", handleScroll);
  }, [currentPage]);


  useEffect(() => {
    getMedias()
  }, [currentPage])

  useEffect(() => {
    setCurrentPage(0)
    getMedias()
    setHasmoreContent(true);
  }, [search, currentLang])


  return (
    <div className="container">
      <div className="moviesContent">
        <>
          <h1 className="moviesTitle">{t('Busca por')}: {typeof (search) === 'object' ? "" : String(search)}</h1>

          <MovieGrid movies={movies} />
          {isLoading ? <div className="centered"><Loader /></div> : ''}

        </>
      </div>
    </div>
  )
}
import React, { useState, useEffect } from "react";
import Article from "../../components/article";
import MediaSlider from "../../components/mediaslider";
import { useTranslation } from "react-i18next";
import SocialIcons from '../../components/layout/footer/socialicons'

import {apiMedias} from "../../service/api";
import FindMediaService from "../../service/findMedia";
import { useNoInitialEffect } from '../../hooks/useNoInitialEffect'

import Loader from "../../components/loader";

import "./style.scss";
import axios from "axios";
import FadeIn from "../../components/fadein";

export default function News() {
  const { t, i18n } = useTranslation();

  const [medias, setMedias] = useState([]);
  const [currentLang, setCurrentLang] = useState(i18n.language);
  const [isLoading, setIsloading] = useState(false);
 
  i18n.on("languageChanged", function (lng) {
    setCurrentLang(lng);
  });

  useNoInitialEffect(() => {
    getMedias()
  }, [currentLang]);

  async function getMedias() {
    setIsloading(true);
    const mediaIdsNovidades = await axios.get('https://integration.ottvs.com.br/ServiceMarketingAssets/GroupView/GetGroupView?groupViewId=2')

    const { Url, Body } = FindMediaService(null, 0, null, null, 0, 2048,"DESC", mediaIdsNovidades.data.map((movie) => { return movie.MediaId; }), currentLang);

    try {
      const result = await apiMedias.post(Url, Body);
      setMedias(result.data.FindMediaResult.Movies.reverse());
      setIsloading(false);
    } catch (e) {
      console.log(e);
      setIsloading(false);
    }
  }

  useEffect(() => {
    getMedias();
  }, []);

  return (
    <FadeIn>
      <div className="container">
      <div className="newsContent">
      <>
        <h1 className="newsTitle">{t("Imprensa")}</h1>
        <Article
          title={t("Lançamentos")}
          text1={t("Lançamentos texto 1")}
        />
        
        <SocialIcons color={'#FFFFFF'} isLeft={true}/>
        <a href="mailto:imprensa@elitefilmesdistribuidora.com.br">imprensa@elitefilmesdistribuidora.com.br </a>

        <Article
          title={t("Novidades")}
          text1={t("Novidades Texto 1")}
          text2={t("Novidades Texto 2")}
        />

      </>

      {isLoading ? (
        <div className="centered">
          <Loader />
        </div>
      ) : (
        <MediaSlider medias={medias} />
      )}
    </div>
    </div>
    </FadeIn>
  );
}

import React from 'react'

import './style.scss'

export default function Loader() {

  return (
    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  )
}









import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import BannerAbout from '../../assets/bannerabout.jpg'

import Article from '../../components/article'
import './style.scss'
import Loader from '../../components/loader'
import FadeIn from '../../components/fadein';

export default function About() {
  const { t } = useTranslation();
  const [isListsLoading, setisListsLoading] = useState(true)

  return (
   <FadeIn>
    <img className="bannerAbout" src={BannerAbout} alt="banner elite filmes" onLoad={() => setisListsLoading(false)}/>
     { isListsLoading && <div className="centered" style={{margin: '0 100px'}}> <Loader/> </div>}
      <div className="container">
      <div className="aboutContent">
        <>
        
          <h1 className="titleAbout">{t('Sobre Elite')}</h1>

          <Article
            title={''}
            text1={t('Sobre Elite parte 1')}
            text2={t('Sobre Elite parte 2')}
            text3={t('Sobre Elite parte 3')}
          />

        </>
      </div>
    </div>
   </FadeIn>
  
  )
}
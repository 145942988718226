import React from 'react'
import './style.scss'

import SocialIcons from './socialicons'

export default function Footer() {
	return (
		<footer className="footer">
      <div className="showInMedumSize">
        <SocialIcons/>
      </div>
      <p>Alameda dos Maracatins, 780 – 7º Andar –  <a href="tel:55 11 5053-5033 ">55 11 5053-5033 </a></p>
      <a href="mailto:contato@elitefilmes.com.br">contato@elitefilmesdistribuidora.com.br</a> 
     
    </footer>
	)
}

import React from 'react'
import './style.scss'

export default function Article(props) {
  return (
    <article className="article">
      <h2 className="title">{props.title}</h2>
      <p className="text">{props?.text1}</p>
      <p className="text">{props?.text2}</p>
      <p className="text">{props?.text3}</p>

    </article>
  )
}

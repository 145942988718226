import axios from 'axios';

const api = axios.create({
  //  baseURL: 'https://service-looke-linux.ottvs.com.br/v1/browser',
   // baseURL: 'https://media-cache.wonderfulrock-4873f0e1.brazilsouth.azurecontainerapps.io',

  // baseURL: 'https://looke-service-media.delightfulwave-5cfdd77b.brazilsouth.azurecontainerapps.io/v1/browser',
  
  //  baseURL: 'https://media-cache.wonderfulrock-4873f0e1.brazilsouth.azurecontainerapps.io',
  //  baseURL: 'https://media-cache--n1-mediasearch-2023-08-28-v1.wonderfulrock-4873f0e1.brazilsouth.azurecontainerapps.io',

   baseURL: 'https://looke-service-media.delightfulwave-5cfdd77b.brazilsouth.azurecontainerapps.io/v1/browser',
    headers: {
      "content-type": "application/json"
    }
})

export const apiMedias = axios.create({
   baseURL: 'https://media-cache.wonderfulrock-4873f0e1.brazilsouth.azurecontainerapps.io',
    headers: {
      "content-type": "application/json"
    }
})

export default api;
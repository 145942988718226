import React from 'react'
import { useTranslation } from 'react-i18next';
import ProfileCard from '../../components/profilecard'
import photoMarcelo from '../../assets/profiles/marcelo.jpg'
import photoBannitz from '../../assets/profiles/bannitz.jpg'
import photoSidney from '../../assets/profiles/sidney.jpg'
import photoMicaela from '../../assets/profiles/micaela.png'
import photoNicolae from '../../assets/profiles/nicolae.png'
import './style.scss'
import FadeIn from '../../components/fadein';

export default function Contact() {
  const { t } = useTranslation();

  return (
    <FadeIn>
      <div className="container">
        <div className="aboutContent">
       
            <h1 className="contactTitle">{t('Conheça Nosso Time')}</h1>

            <div className="profileWGrouprapper">

              <ProfileCard
                photo={photoMarcelo}
                name="Marcelo Spinasse"
                position="CEO"
                email="marcelo.nunes@encripta.com.br"

              />

              <div className="profileWrapper">
                <ProfileCard
                  photo={photoBannitz}
                  name="Luiz Bannitz"
                  position={t("BannitzRole")}
                  email="luiz.bannitz@encripta.com.br"
                />
              </div>
              {/* <div className="profileWrapper">
                <ProfileCard
                  photo={photoSidney}
                  name="Sidney Gennaro"
                  position="Head International Sales & Acquisition CCO"
                  email="sidney.gennaro@encripta.com.br"
                />
              </div> */}

              <div className="profileWrapper">
                <ProfileCard
                  photo={photoNicolae}
                  name="Nicolae Arnizaut"
                  position={t("NicolaeRole")}
                  email="nicolae@encripta.com.br"
                />
              </div>

              <div className="profileWrapper">
                <ProfileCard
                  photo={photoMicaela}
                  name="Micaela Nunes"
                  position={t("MicaelaRole")}
                  email="micaela.nunes@encripta.com.br"
                />
              </div>


        
          </div>
        </div>
        </div>
    </FadeIn>
  )
}
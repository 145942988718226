import React from 'react'
import './style.scss'

import LogoFacebook from '../../../../assets/facebook.svg'
import LogoInstagram from '../../../../assets/instagram.svg'
import LogoLinkedin from '../../../../assets/linkedin.svg'
import LogoYoutube from '../../../../assets/youtube.svg'

export default function SocialIcons({color, isLeft}) {

	return (

      <ul className={isLeft ? "socialIconsWrapper alignSocialLeft" : "socialIconsWrapper alignSocialCenter"}>
        <li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/DistribuidoraEliteFilmes/"><img src={LogoFacebook} alt="social logo facebook"/></a></li>
        <li><a  target="_blank"rel="noreferrer" href="https://www.instagram.com/distribuidoraelitefilmes"><img src={LogoInstagram} alt="social logo Instagram"/></a></li>
        <li><a  target="_blank"rel="noreferrer" href="https://www.youtube.com/c/EliteFilmesDistribuidora"><img src={LogoYoutube} alt="social logo Youtube"/></a></li>
        <li><a  target="_blank"rel="noreferrer" href="https://www.linkedin.com/company/elite-filmes/"><img src={LogoLinkedin} alt="social logo Linkedin"/></a></li>
      </ul>
	)
}

import React from 'react'
import MovieTabItem from './movietabitem'

import './style.scss'

export default function MovieTabList({ sections, getGenre }) {

  return (
    <>
        <ul className="movieTabList">
          {
            sections.map((section, i) => {
              return(
                <MovieTabItem section={section} active={section.active} getGenre={getGenre} i={i} key={i}/>
              )
            })
          }
         
        </ul>
    </>
  )
}

import React, { useState, useEffect } from 'react';
import BannerSlider from '../../components/bannerslider';
import SectionSlier from '../../components/sectionSlider'
import api from '../../service/api'
import { ListBannerService } from '../../service/listBanner'
import { useTranslation } from 'react-i18next';
import { groupTitles } from '../../config'
import { useNoInitialEffect } from '../../hooks/useNoInitialEffect'

import './style.scss'

export default function Home() {
  const { i18n, t } = useTranslation();

  const [banners, setBanners] = useState([])
  const [currentLang, setCurrentLang] = useState(i18n.language)


  i18n.on('languageChanged', function (lng) {
    setCurrentLang(lng)
  })

  useNoInitialEffect(() => {
    getBanners()
  }, [currentLang])

  useEffect(() => {
    getBanners()
  }, []);

  async function getBanners() {
    const { Url, Body } = ListBannerService(currentLang);
    try {
      const result = await api.post(Url, Body)
      setBanners(result.data.ListBannerResult.Banners)

    } catch (e) {
      console.log(e)
    }
  }

  const listOrderIds = [
    19,
    2,
    1,
    13
  ]

  const homeList = [];
  listOrderIds.forEach((listOrderId) => {
    let list = groupTitles.find((item) => item.id === listOrderId)
    homeList.push(list)
  })

  return (
    <>
      <div className="noPaddingContainer">
        <BannerSlider banners={banners} />
      </div>
      {
        homeList.map((list) => {
          return (
            <SectionSlier
              lang={currentLang}
              groupViewId={list.id}
              title={t(list.title)}
              sortCriteria={list.ListBannerResult}
              sortOrder={list.sortOrder}
            />
          )
        })
      }
    </>
  )
}
